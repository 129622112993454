export default [
  {
    question: 'What is MU Safe Space?',
    paras: [
      'Safe Space exists to provide a safe space for all musicians to share instances of sexism, sexual harassment and sexual abuse in the music industry.',
      'If you have, whatever your role in the music industry, you can report it in confidence using this service.',
      'All your answers will be treated in the strictest confidence. Only four members of staff will have access to your answers, which we will use to inform our work on making the music industry safer.',
    ],
  },
  {
    question: 'What is sexual harassment?',
    paras: [
      'Sexual harassment is when someone behaves in a way that makes you feel distressed, intimidated or offended and the behaviour is of a sexual nature.',
      'Physical behaviour: unwelcome sexual advances, touching and various forms of sexual assault.',
      'Displaying photos, pictures or drawings of a sexual nature.',
      'Sending messages, emails etc. with sexual content.',
      "Learn more on <a href='http://themu.org/'>theMU.org</a>",
    ],
  },
  {
    question: 'What is sexual assault?',
    paras: [
      'Sexual assault is any type of sexual activity or contact that is not consented to.',
    ],
  },
  {
    question: 'Is the MU Safe Space for you?',
    paras: [
      'We want to hear from you if you already make or wish to make all or part of your living from music. You might be a student, a professional; be employed, self employed, not working at the moment, or looking for work.',
      'We also want to hear from you if you are an amateur musician. You might be playing in a community orchestra or performing as part of a choir.',
      'We need as many musicians as possible to share their experiences. The more experiences musicians share, the easier it will be for us to spot patterns and identify where we need to focus our work to create meaningful and lasting change.',
      'If you are aged 16 or under, or the incident happened when you were 16 or under, it will not be covered by this survey.',
      "Please contact <a href='https://rapecrisis.org.uk/'>Rape Crisis</a> or the <a href='https://www.nspcc.org.uk/'>NSPCC</a> for advice and support. You can also find a full list of support organisations on our <a href='/support'>Support Services page</a>.",
      "If you need an immediate help and support, check the services you can contact now: <a href='https://www.musiciansunion.org.uk/Home/Advice/Your-Career/Sexual-Harassment-and-Discrimination/help-and-support'>MU Help & Support</a>.",
    ],
  },
  {
    question: 'What can the MU Safe Space do?',
    paras: [
      'For MU members:',
      'We can advise you on your legal rights.',
      'We can provide information about relevant support services.',
      'We can support you if you would like to seek redress.',
      'We may be able to raise a complaint with the employer, engager, or even directly with the perpetrator.',
      'If both the alleged perpetrator and the complainant are MU members, then we also have the option of disciplinary action under MU rules.',
      'Please keep a record of the unique ID number given at the end of the questions, this can be used later to reference your report if you so wish.',
      'For everyone:',
      'We can provide advice on what your rights are.',
      'We can also provide information about relevant support services.',
      'In cases of bullying, discrimination and other inappropriate behaviour we can provide advice on your options and further steps.',
    ],
  },
  {
    question: 'What can the MU Safe Space not do?',
    paras: [
      'We cannot provide counselling. But we will always provide information on relevant support services.',
      'We are very limited in terms of what can be done industrially to address a complaint of a sexual assault.',
      'We cannot guarantee the outcome that you want.',
    ],
  },
];
