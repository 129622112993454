const firstQuestions = [
  {
    explainer: 'Please select all that apply.',
    page: 0,
    section: 0,
    other: true,
    content: [
      'Sexual comments or jokes',
      'Unwelcome sexual advances',
      'Unwanted touching',
      'A form of sexual assault',
      'Displaying pictures, photos or drawings of sexual nature',
      'Receiving unwanted messages with sexual content',
      'Other (please specify)',
    ],
    question: 'What form of sexual harrassment did you experience?',
    type: 'checkbox',
  },
  {
    page: 1,
    section: 0,
    question: 'When did the incident take place?',
    type: 'date',
  },
  {
    page: 1,
    section: 0,
    content: [
      'There was a single incident in this period',
      'There were a number of occurrences within this period',
    ],
    question: 'Were there multiple incidents related to the same perpetrator?',
    type: 'radio',
  },
  {
    page: 2,
    section: 0,
    content: ['In the UK', 'Outside the UK'],
    question: 'Did the incident take place in the UK?',
    type: 'radio',
  },
  {
    explainer: 'Please select all that apply.',
    page: 2,
    section: 0,
    other: true,
    content: [
      'Online',
      'Music venue',
      'Function venue',
      'Music festival',
      'Studio',
      'Busking',
      'Orchestra pit',
      'Theatre',
      'On tour',
      'School, college or university',
      'Outside of work',
      'Other (please specify)',
    ],
    question: 'What kind of location did the incident take place in?',
    type: 'checkbox',
  },
  {
    explainer:
      "Only fill this in if you'd like to specifically identify the location of the incident.",
    page: 2,
    section: 0,
    content: ['Write the location here'],
    question: 'Where exactly did the incident take place?',
    type: 'text',
  },
  {
    explainer: "You can share as much or as little as you'd like.",
    page: 3,
    section: 0,
    content: ['Please describe your experience here'],
    question: 'Describe what happened in your own words.',
    type: 'textarea',
  },
  {
    page: 4,
    section: 1,
    content: ['Female ', 'Male ', 'Mixed group', 'Not sure'],
    question: 'Was the person who did this:',
    type: 'radio',
  },
  {
    explainer: 'Please select all that apply.',
    page: 5,
    section: 1,
    other: true,
    content: [
      'Manager or agent',
      'Employer, engager or fixer',
      'Senior figure',
      'Client',
      'Teacher',
      'Audience member',
      'Colleague',
      'Student',
      'Label or publisher',
      'Not sure',
      'Unknown / stranger',
      'Other (please specify)',
    ],
    question: 'What is their relationship to you?',
    type: 'checkbox',
  },
  {
    page: 6,
    section: 1,
    content: ['Yes', 'No', 'Not sure'],
    question:
      'Have you experienced sexual harassment from the same perpetrator before?',
    type: 'radio',
  },
  {
    page: 6,
    section: 1,
    content: ['Yes', 'No', 'Not sure'],
    question:
      'Do you know if anyone else has been harassed by this person before?',
    type: 'radio',
  },
  {
    page: 7,
    section: 2,
    content: ['Yes', 'No'],
    question: 'Have you reported this incident elsewhere?',
    type: 'radio',
  },
  {
    condition: ['Yes'],
    explainer: 'Please select all that apply.',
    page: 8,
    section: 2,
    other: true,
    content: [
      'Manager or agent',
      'Employer, engager or fixer',
      'Client',
      'Colleague',
      'Police',
      'Support services (e.g. SARC, Rape Crisis etc.)',
      'School, college or university',
      "Musicians' Union (by another means)",
      'Other (please specify)',
    ],
    split: 'Have you reported this incident elsewhere?',
    question: 'Who did you report this incident to?',
    type: 'checkbox',
  },
  {
    condition: ['Yes'],
    page: 8,
    section: 2,
    content: ['Yes', 'No', "Don't know"],
    split: 'Have you reported this incident elsewhere?',
    question: 'Was any action taken?',
    type: 'radio',
  },
  {
    condition: ['Yes'],
    page: 8,
    section: 2,
    content: ['Very', 'Somewhat', 'Not at all'],
    split: 'Have you reported this incident elsewhere?',
    question: 'How satisfied are you with the way your report(s) was handled?',
    type: 'radio',
  },
  {
    condition: ['No'],
    explainer: 'Please select all that apply.',
    page: 8,
    section: 2,
    other: true,
    content: [
      'I thought I might not be believed or taken seriously',
      'I was scared of losing work',
      'The workplace culture',
      'I thought the issue would not be properly handled',
      'I was scared of the person who did this',
      'There were no witnesses',
      "I didn't know who I could report to",
      'Other (please specify)',
    ],
    split: 'Have you reported this incident elsewhere?',
    question: 'What discouraged you from reporting?',
    type: 'checkbox',
  },
  {
    explainer: 'Please select all that apply.',
    page: 9,
    section: 3,
    other: true,
    content: [
      'Employed',
      'Self-employed',
      'Not working at the time',
      'Other (please specify)',
    ],
    question: 'What was your employment status at the time of the incident?',
    type: 'checkbox',
  },
  {
    explainer:
      'Please select all that apply. This will help us identify how sexism, harrassment and abuse manifest in different spaces.',
    page: 10,
    section: 3,
    other: true,
    content: [
      'Musician',
      'Featured Artist',
      'Orchestral player',
      'Composer, songwriter or lyricist',
      'Music producer',
      'Music engineer',
      'Record label',
      'Music teacher',
      'Student',
      'Session player',
      'Other (please specify)',
    ],
    question: 'What work have you been performing at the time of the incident?',
    type: 'checkbox',
  },
  {
    page: 11,
    section: 3,
    content: ['16-18', '18-24', '25-34', '35-44', '45-54', '55-64', 'Over 65'],
    question: 'What age were you at the time of the incident?',
    type: 'radio',
  },
  {
    page: 12,
    section: 3,
    other: true,
    content: ['Female ', 'Male ', 'Non-binary', 'Other (please specify)'],
    question: 'What is your gender?',
    type: 'radio',
  },
  {
    page: 12,
    section: 3,
    content: ['Yes', 'No'],
    question: 'Is your gender identity the same as that assigned at birth?',
    type: 'radio',
  },
  {
    page: 13,
    section: 3,
    other: true,
    content: [
      'White - British, English, Northern Irish, Scottish, Welsh',
      'White - Irish',
      'White - Gypsy, Roma or Irish Traveller',
      'White - European',
      'White - Any other White background',
      'Mixed / Dual heritage - White & Black Caribbean',
      'Mixed / Dual heritage - White & Black African',
      'Mixed / Dual heritage - White & Asian',
      'Mixed / Dual heritage - Any other Mixed / Dual heritage background',
      'Asian / Asian British - Indian',
      'Asian / Asian British - Pakistani',
      'Asian / Asian British - Bangladeshi',
      'Asian / Asian British - Chinese',
      'Asian / Asian British - Other Asian background',
      'Black / African - African',
      'Black - British',
      'Black / Caribbean - Caribbean',
      'Black - Any other African / Caribbean background',
      'Arab',
      'Other (please specify)',
    ],
    question: 'What option best describes your ethinicity?',
    type: 'radio',
  },
  {
    page: 14,
    section: 3,
    content: ['Yes', 'No'],
    question: 'Do you identify as a deaf or disabled person?',
    type: 'radio',
  },
  {
    page: 15,
    section: 3,
    other: true,
    content: [
      'Asexual',
      'Bi / Bisexual',
      'Gay man',
      'Gay woman / lesbian',
      'Heterosexual / straight',
      'Queer',
      'Other (please specify)',
    ],
    question: 'Which of the following best describes your sexuality?',
    type: 'radio',
  },
];

const witnessQuestions = [
  {
    explainer: 'Please select all that apply.',
    page: 0,
    section: 0,
    other: true,
    content: [
      'Sexual comments or jokes',
      'Unwelcome sexual advances',
      'Unwanted touching',
      'A form of sexual assault',
      'Displaying pictures, photos or drawings of sexual nature',
      'Receiving unwanted messages with sexual content',
      'Other (please specify)',
    ],
    question: 'What form of sexual harrassment did you experience?',
    type: 'checkbox',
  },
  {
    page: 1,
    section: 0,
    question: 'When did the incident take place?',
    type: 'date',
  },
  {
    page: 1,
    section: 0,
    content: [
      'There was a single incident in this period',
      'There were a number of occurrences within this period',
    ],
    question: 'Were there multiple incidents related to the same perpetrator?',
    type: 'radio',
  },
  {
    page: 2,
    section: 0,
    content: ['In the UK', 'Outside the UK'],
    question: 'Did the incident take place in the UK?',
    type: 'radio',
  },
  {
    explainer: 'Please select all that apply.',
    page: 2,
    section: 0,
    other: true,
    content: [
      'Online',
      'Music venue',
      'Function venue',
      'Music festival',
      'Studio',
      'Busking',
      'Orchestra pit',
      'Theatre',
      'On tour',
      'School, college or university',
      'Outside of work',
      'Other (please specify)',
    ],
    question: 'What kind of location did the incident take place in?',
    type: 'checkbox',
  },
  {
    explainer:
      "Only fill this in if you'd like to specifically identify the location of the incident.",
    page: 2,
    section: 0,
    content: ['Write the location here'],
    question: 'Where exactly did the incident take place?',
    type: 'text',
  },
  {
    explainer: "You can share as much or as little as you'd like.",
    page: 3,
    section: 0,
    content: ['Please describe your experience here'],
    question: 'Describe what happened in your own words.',
    type: 'textarea',
  },
  {
    page: 4,
    section: 1,
    content: ['Female ', 'Male ', 'Mixed group', 'Not sure'],
    question: 'Was the person who did this:',
    type: 'radio',
  },
  {
    explainer: 'Please select all that apply.',
    page: 5,
    section: 1,
    other: true,
    content: [
      'Manager or agent',
      'Employer, engager or fixer',
      'Senior figure',
      'Client',
      'Teacher',
      'Audience member',
      'Colleague',
      'Student',
      'Label or publisher',
      'Not sure',
      'Unknown / stranger',
      'Other (please specify)',
    ],
    question: 'What is their relationship to you?',
    type: 'checkbox',
  },
  {
    page: 6,
    section: 1,
    content: ['Yes', 'No', 'Not sure'],
    question:
      'Have you experienced sexual harassment from the same perpetrator before?',
    type: 'radio',
  },
  {
    page: 6,
    section: 1,
    content: ['Yes', 'No', 'Not sure'],
    question:
      'Do you know if anyone else has been harassed by this person before?',
    type: 'radio',
  },
  {
    page: 7,
    section: 2,
    content: ['Yes', 'No'],
    question: 'Have you reported this incident elsewhere?',
    type: 'radio',
  },
  {
    condition: ['Yes'],
    explainer: 'Please select all that apply.',
    page: 8,
    section: 2,
    other: true,
    content: [
      'Manager or agent',
      'Employer, engager or fixer',
      'Client',
      'Colleague',
      'Police',
      'Support services (e.g. SARC, Rape Crisis etc.)',
      'School, college or university',
      "Musicians' Union (by another means)",
      'Other (please specify)',
    ],
    split: 'Have you reported this incident elsewhere?',
    question: 'Who did you report this incident to?',
    type: 'checkbox',
  },
  {
    condition: ['Yes'],
    page: 8,
    section: 2,
    content: ['Yes', 'No', "Don't know"],
    split: 'Have you reported this incident elsewhere?',
    question: 'Was any action taken?',
    type: 'radio',
  },
  {
    condition: ['Yes'],
    page: 8,
    section: 2,
    content: ['Very', 'Somewhat', 'Not at all'],
    split: 'Have you reported this incident elsewhere?',
    question: 'How satisfied are you with the way your report(s) was handled?',
    type: 'radio',
  },
  {
    condition: ['No'],
    explainer: 'Please select all that apply.',
    page: 8,
    section: 2,
    other: true,
    content: [
      'I thought I might not be believed or taken seriously',
      'I was scared of losing work',
      'The workplace culture',
      'I thought the issue would not be properly handled',
      'I was scared of the person who did this',
      'There were no witnesses',
      "I didn't know who I could report to",
      'Other (please specify)',
    ],
    split: 'Have you reported this incident elsewhere?',
    question: 'What discouraged you from reporting?',
    type: 'checkbox',
  },
  {
    explainer: 'Please select all that apply.',
    page: 9,
    section: 3,
    other: true,
    content: [
      'Employed',
      'Self-employed',
      'Not working at the time',
      'Other (please specify)',
    ],
    question: 'What was your employment status at the time of the incident?',
    type: 'checkbox',
  },
  {
    explainer:
      'Please select all that apply. This will help us identify how sexism, harrassment and abuse manifest in different spaces.',
    page: 10,
    section: 3,
    other: true,
    content: [
      'Musician',
      'Featured Artist',
      'Orchestral player',
      'Composer, songwriter or lyricist',
      'Music producer',
      'Music engineer',
      'Record label',
      'Music teacher',
      'Student',
      'Session player',
      'Other (please specify)',
    ],
    question: 'What work have you been performing at the time of the incident?',
    type: 'checkbox',
  },
  {
    page: 11,
    section: 3,
    content: ['16-18', '18-24', '25-34', '35-44', '45-54', '55-64', 'Over 65'],
    question: 'What age were you at the time of the incident?',
    type: 'radio',
  },
  {
    page: 12,
    section: 3,
    other: true,
    content: ['Female ', 'Male ', 'Non-binary', 'Other (please specify)'],
    question: 'What is your gender?',
    type: 'radio',
  },
  {
    page: 12,
    section: 3,
    content: ['Yes', 'No'],
    question: 'Is your gender identity the same as that assigned at birth?',
    type: 'radio',
  },
  {
    page: 13,
    section: 3,
    other: true,
    content: [
      'White - British, English, Northern Irish, Scottish, Welsh',
      'White - Irish',
      'White - Gypsy, Roma or Irish Traveller',
      'White - European',
      'White - Any other White background',
      'Mixed / Dual heritage - White & Black Caribbean',
      'Mixed / Dual heritage - White & Black African',
      'Mixed / Dual heritage - White & Asian',
      'Mixed / Dual heritage - Any other Mixed / Dual heritage background',
      'Asian / Asian British - Indian',
      'Asian / Asian British - Pakistani',
      'Asian / Asian British - Bangladeshi',
      'Asian / Asian British - Chinese',
      'Asian / Asian British - Other Asian background',
      'Black / African - African',
      'Black - British',
      'Black / Caribbean - Caribbean',
      'Black - Any other African / Caribbean background',
      'Arab',
      'Other (please specify)',
    ],
    question: 'What option best describes your ethinicity?',
    type: 'radio',
  },
  {
    page: 14,
    section: 3,
    content: ['Yes', 'No'],
    question: 'Do you identify as a deaf or disabled person?',
    type: 'radio',
  },
  {
    page: 15,
    section: 3,
    other: true,
    content: [
      'Asexual',
      'Bi / Bisexual',
      'Gay man',
      'Gay woman / lesbian',
      'Heterosexual / straight',
      'Queer',
      'Other (please specify)',
    ],
    question: 'Which of the following best describes your sexuality?',
    type: 'radio',
  },
];

export { firstQuestions, witnessQuestions };
